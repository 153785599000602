import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import axios from 'axios'
import 'element-plus/dist/index.css'
import './assets/fonts/fonts.css'
import VueCookies from 'vue3-cookies'
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
const app = createApp(App)
app.config.globalProperties.$axios = axios
app.component('MazPhoneNumberInput', MazPhoneNumberInput)
createApp(App).use(store).use(router).use(ElementPlus).use(VueCookies).mount('#app')
