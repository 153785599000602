import { createRouter, createWebHashHistory } from 'vue-router'
// import HomeView from '../views/HomePage.vue'
import ComingSoon from '../views/ComingSoon.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: ComingSoon
  },
  {
    path: '/about',
    name: 'about',
    component: ComingSoon
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
