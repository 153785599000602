<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"/>
<template>
  <!-- <NaV></NaV> -->
  <div class="background">
    <div class=bg>
      <div class="layout">
          <el-image class="mobilogo" :src="require('@/assets/images/Richblue/Horizon_Classic.png')"></el-image>
        <div class="content" @mouseout="leave" @mouseover="enter">
              <div class="page1" v-if="activeVar==0">
                <div class="coming">Coming Soon</div>
                <div class="soon"><span style="font-family:'Outfit-Regular';">Mobi+</span>&nbsp;马上就来</div>
              </div>
              <div class="page2"  v-if="activeVar==1">
                  <div class="text1">赋能管理全球优秀人才
                    <p>全球合规雇佣与外派管理  数字化一站式服务解决方案</p>
                  </div>
                  <div class="text2">Enabling the management of global talent
                    <p>Global Employment & Mobility One-Stop Digital HRM Solution</p>
                  </div>
                <div class="introduce">
                  <div class="box">
                      <div class="global">
                        <img :src="require('@/assets/Icon/GlobalMobility.png')">
                      </div>
                      <div class="gtextbox">
                        <span class="gtext1">Global Mobility</span>
                        <p class="gtext2">全球外派管理</p>
                      </div>
                  </div>
                  <div class="box">
                      <div class="global">
                        <img :src="require('@/assets/Icon/GlobalEmployment.png')">
                      </div>
                        <div class="gtextbox">
                        <span class="gtext1">Global Employment</span>
                        <p class="gtext2">全球雇佣服务</p>
                      </div>
                  </div>
                  <div class="box">
                    <div class="global">
                        <img :src="require('@/assets/Icon/GlobalRecruitment.png')"/>
                    </div>
                        <div class="gtextbox">
                        <span class="gtext1">Global Recruitment</span>
                        <p class="gtext2">全球招聘</p>
                      </div>
                  </div>
                  <div class="box">
                    <div class="global">
                      <img :src="require('@/assets/Icon/GlobalEmployee Benefits.png')"/>
                    </div>
                    <div class="gtextbox">
                      <span class="gtext1">Global Employee Benefits</span>
                      <p class="gtext2">全球员工福利及保障</p>
                    </div>
                  </div>
                </div>
              </div>
            <div class="page3"  v-if="activeVar==2">
              <div class="contactbox">
                    <div class="contact">
                      <div class="chinese">
                        联系我们
                        <p class="chi2">如果您有任何需要</p>
                      </div>
                      <el-image class="ban" :src="require('@/assets/Icon/ban.png')"></el-image>
                      <div class="english">
                        Contact us
                        <p class="eng2">if you have any question.</p>
                      </div>
                    </div>
                  <div class="e-mail">
                    <el-text class="e-title">E-mail:</el-text>
                    <a class="e-tail" href="mailto:servicedesk@mobiplushrs.com"> servicedesk@mobiplushrs.com</a>
                  </div>
              </div>
            <div class="formbox" v-if="submitform==false">
              <div class="form">
                <div class="text">填写表单，我们来联系您</div>
                <el-form
                :label-position="top"
                :model="form"
                style="width: 100%;"
                >
                <el-form-item class="formitem0">
                  <el-text class="formlabel">我们怎么称呼您？</el-text>
                  <div class="divforminput">
                    <input class="forminput" placeholder="姓名" required v-model="form.uname"/>
                  </div>
                </el-form-item>
                <el-form-item class="formitem2">
                  <el-text class="formlabel">我们怎么联系到您？</el-text>
                    <div class="inputphone">
                      <div class="it-select" placeholder="区号">
                        <span class="it-select-input" @click.stop="openSelect">
                          {{ phonecode }}</span
                        >
                        <!-- <span
                          :class="['triangle-down', { rotate: rotate }]"
                          @click="openSelect"
                        ></span> -->
                        <img class="uparrow" :src="require('@/assets/Icon/ArrowUp.png')"/>
                        <div
                          :class="[
                            'it-select-options-panel',
                            showOptions ? 'show' : 'hidden',
                          ]"
                        >
                            <input class="it-search" placeholder="Search" @input="searchcode(searchcountry)" v-model="searchcountry">
                          <div v-if="searchcountry!=''">
                            <div
                                class="it-select-option"
                                @click="getValue(item.tel, item.name)"
                                v-for="(item, index) in newdata"
                                :key="index"
                              >
                                {{ item.name }} &nbsp;(+{{item.tel}})
                            </div>
                          </div>
                          <div v-if="searchcountry==''">
                            <div
                                class="it-select-option"
                                @click="getValue(item.tel, item.name)"
                                v-for="(item, index) in countrycode"
                                :key="index"
                              >
                                {{ item.name }} &nbsp;(+{{item.tel}})
                            </div>
                          </div>
                        </div>
                      </div>
                      <input placeholder="电话" class="forminput" type="tel"  v-model="form.phone"
                      @input="form.phone=form.phone.replace(/[^0-9]/g,'')"/>
                    </div>
                    <div class="inputemail" style="margin-top: 4px;">
                      <input placeholder="邮箱" class="forminput" type="email"  v-model="form.email"/>
                    </div>
                </el-form-item>
                <el-form-item class="formitem1">
                  <el-text class="formlabel">您的身份是？</el-text>
                  <div class="divforminput">
                  <input placeholder="职务" @click="openValue" class="forminput"  v-model=" form.career"/>
                  <img class="uparrow" :src="require('@/assets/Icon/ArrowUp.png')"/>
                  </div>
                  <div class="list" v-if="ifcarshow">
                  <ul>
                    <li v-for="(item,index) in careerop" :key="index" @click="getcarvalue(index,item)">
                      {{ item.type }}
                    </li>
                  </ul>
                  </div>
                </el-form-item>
                <el-form-item class="formitem3">
                  <el-text class="formlabel">您需要帮助的业务是？</el-text>
                  <div class="divforminput">
                  <input placeholder="业务" @click="openbusValue" class="forminput"  v-model="form.business"/>
                  <img class="uparrow" :src="require('@/assets/Icon/ArrowUp.png')"/>
                  </div>
                  <div class="list" v-if="ifbusshow">
                  <ul>
                    <li v-for="(item,index) in busop" :key="index" @click="getbusvalue(index,item)" >
                      {{ item.type }}
                    </li>
                  </ul>
                  </div>
                </el-form-item>
                </el-form>
                <div class="button">
                  <button @click="submit" class="submit">发送 / Submit</button>
                </div>
              </div>
            </div>
              <div class="afterform" v-if="submitform">
                <el-text class="aftertext">我们已收到您的消息，<br>并将尽快回复您！</el-text>
              </div>
            </div>
        </div>
          <div class="select">
            <el-image style="cursor: pointer;" :src="require('@/assets/Icon/Arrow.png')" @click="turnleft"></el-image>
            <div class="rectangle" v-for="(item,index) in pagelist" :key="index"
            :class="{'activerec':activeVar==index}" @click="activeFun(item,index)">
            </div>
            <el-image style="cursor: pointer;" :src="require('@/assets/Icon/ArrowR.png')" @click="turnright"></el-image>
          </div>
      </div>
    </div>
    <div class="wavebg">
        <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
          <defs>
          <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g class="parallax">
          <use xlink:href="#gentle-wave" x="48" y="0" fill="#0029FF" opacity="0.2" />
          <use xlink:href="#gentle-wave" x="48" y="3" fill="#0029FF" opacity="0.4" />
          <use xlink:href="#gentle-wave" x="48" y="9" fill="#0029FF" opacity="0.5" />
          <use xlink:href="#gentle-wave" x="48" y="15" fill="#0029FF" opacity="0.6" />
          </g>
        </svg>
      </div>
  </div>

</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { ElMessage } from 'element-plus'
import axios from 'axios'
// import axios from 'axios';
// import NaV from '@/components/NaviGator.vue'
const activeVar = ref(0)
// const phoneNumber = ref('')
const ifcarshow = ref(false)
const ifbusshow = ref(false)
const submitform = ref(false)
const phonecode = ref('')
const newdata = ref([])
const searchcountry = ref('')
// const options = ref()
const rotate = ref(false)
const showOptions = ref(false)
const searchcode = (abc) => {
  newdata.value = []
  // console.log(abc)
  countrycode.value.filter((arr) => {
    if (abc !== '' && (arr.name.includes(abc) || arr.en.includes(abc))) {
      newdata.value.push(arr)
      // console.log(abc)
    } else if (abc === '') {
      newdata.value = []
    }
    return null
  })
}
const openSelect = () => {
  showOptions.value = !showOptions.value
  rotate.value = !rotate.value
}
const getValue = (tel, name) => {
  phonecode.value = '+' + tel
  showOptions.value = false
  rotate.value = false
}
const careerop = ref([{
  type: 'HRBP'
},
{
  type: 'COE'
},
{
  type: 'SSC'
},
{
  type: '被派遣的员工'
}])
const busop = ref([{
  type: '海外派遣/雇佣'
},
{
  type: '海外薪酬'
},
{
  type: '海外招聘/求职'
},
{
  type: '海外搬家/流动'
},
{
  type: '个人/家庭签证'
},
{
  type: '海外安居'
},
{
  type: '当地法律实体注册'
}])
const countrycode = ref([{
  short: 'AD',
  name: '安道尔共和国',
  en: 'Andorra',
  tel: '376'
}, {
  short: 'AE',
  name: '阿拉伯联合酋长国',
  en: 'UnitedArabEmirates',
  tel: '971'
}, {
  short: 'AF',
  name: '阿富汗',
  en: 'Afghanistan',
  tel: '93'
}, {
  short: 'AG',
  name: '安提瓜和巴布达',
  en: 'AntiguaandBarbuda',
  tel: '1268'
}, {
  short: 'AI',
  name: '安圭拉岛',
  en: 'Anguilla',
  tel: '1264'
}, {
  short: 'AL',
  name: '阿尔巴尼亚',
  en: 'Albania',
  tel: '355'
}, {
  short: 'AM',
  name: '亚美尼亚',
  en: 'Armenia',
  tel: '374'
}, {
  short: '',
  name: '阿森松',
  en: 'Ascension',
  tel: '247'
}, {
  short: 'AO',
  name: '安哥拉',
  en: 'Angola',
  tel: '244'
}, {
  short: 'AR',
  name: '阿根廷',
  en: 'Argentina',
  tel: '54'
}, {
  short: 'AT',
  name: '奥地利',
  en: 'Austria',
  tel: '43'
}, {
  short: 'AU',
  name: '澳大利亚',
  en: 'Australia',
  tel: '61'
}, {
  short: 'AZ',
  name: '阿塞拜疆',
  en: 'Azerbaijan',
  tel: '994'
}, {
  short: 'BB',
  name: '巴巴多斯',
  en: 'Barbados',
  tel: '1246'
}, {
  short: 'BD',
  name: '孟加拉国',
  en: 'Bangladesh',
  tel: '880'
}, {
  short: 'BE',
  name: '比利时',
  en: 'Belgium',
  tel: '32'
}, {
  short: 'BF',
  name: '布基纳法索',
  en: 'Burkina-faso',
  tel: '226'
}, {
  short: 'BG',
  name: '保加利亚',
  en: 'Bulgaria',
  tel: '359'
}, {
  short: 'BH',
  name: '巴林',
  en: 'Bahrain',
  tel: '973'
}, {
  short: 'BI',
  name: '布隆迪',
  en: 'Burundi',
  tel: '257'
}, {
  short: 'BJ',
  name: '贝宁',
  en: 'Benin',
  tel: '229'
}, {
  short: 'BL',
  name: '巴勒斯坦',
  en: 'Palestine',
  tel: '970'
}, {
  short: 'BM',
  name: '百慕大群岛',
  en: 'BermudaIs.',
  tel: '1441'
}, {
  short: 'BN',
  name: '文莱',
  en: 'Brunei',
  tel: '673'
}, {
  short: 'BO',
  name: '玻利维亚',
  en: 'Bolivia',
  tel: '591'
}, {
  short: 'BR',
  name: '巴西',
  en: 'Brazil',
  tel: '55'
}, {
  short: 'BS',
  name: '巴哈马',
  en: 'Bahamas',
  tel: '1242'
}, {
  short: 'BW',
  name: '博茨瓦纳',
  en: 'Botswana',
  tel: '267'
}, {
  short: 'BY',
  name: '白俄罗斯',
  en: 'Belarus',
  tel: '375'
}, {
  short: 'BZ',
  name: '伯利兹',
  en: 'Belize',
  tel: '501'
}, {
  short: 'CA',
  name: '加拿大',
  en: 'Canada',
  tel: '1'
}, {
  short: 'KY',
  name: '开曼群岛',
  en: 'CaymanIs.',
  tel: '1345'
}, {
  short: 'CF',
  name: '中非共和国',
  en: 'CentralAfricanRepublic',
  tel: '236'
}, {
  short: 'CG',
  name: '刚果',
  en: 'Congo',
  tel: '242'
}, {
  short: 'CH',
  name: '瑞士',
  en: 'Switzerland',
  tel: '41'
}, {
  short: 'CK',
  name: '库克群岛',
  en: 'CookIs.',
  tel: '682'
}, {
  short: 'CL',
  name: '智利',
  en: 'Chile',
  tel: '56'
}, {
  short: 'CM',
  name: '喀麦隆',
  en: 'Cameroon',
  tel: '237'
}, {
  short: 'CN',
  name: '中国',
  en: 'China',
  tel: '86'
}, {
  short: 'CO',
  name: '哥伦比亚',
  en: 'Colombia',
  tel: '57'
}, {
  short: 'CR',
  name: '哥斯达黎加',
  en: 'CostaRica',
  tel: '506'
}, {
  short: 'CS',
  name: '捷克',
  en: 'Czech',
  tel: '420'
}, {
  short: 'CU',
  name: '古巴',
  en: 'Cuba',
  tel: '53'
}, {
  short: 'CY',
  name: '塞浦路斯',
  en: 'Cyprus',
  tel: '357'
}, {
  short: 'CZ',
  name: '捷克',
  en: 'CzechRepublic',
  tel: '420'
}, {
  short: 'DE',
  name: '德国',
  en: 'Germany',
  tel: '49'
}, {
  short: 'DJ',
  name: '吉布提',
  en: 'Djibouti',
  tel: '253'
}, {
  short: 'DK',
  name: '丹麦',
  en: 'Denmark',
  tel: '45'
}, {
  short: 'DO',
  name: '多米尼加共和国',
  en: 'DominicaRep.',
  tel: '1890'
}, {
  short: 'DZ',
  name: '阿尔及利亚',
  en: 'Algeria',
  tel: '213'
}, {
  short: 'EC',
  name: '厄瓜多尔',
  en: 'Ecuador',
  tel: '593'
}, {
  short: 'EE',
  name: '爱沙尼亚',
  en: 'Estonia',
  tel: '372'
}, {
  short: 'EG',
  name: '埃及',
  en: 'Egypt',
  tel: '20'
}, {
  short: 'ES',
  name: '西班牙',
  en: 'Spain',
  tel: '34'
}, {
  short: 'ET',
  name: '埃塞俄比亚',
  en: 'Ethiopia',
  tel: '251'
}, {
  short: 'FI',
  name: '芬兰',
  en: 'Finland',
  tel: '358'
}, {
  short: 'FJ',
  name: '斐济',
  en: 'Fiji',
  tel: '679'
}, {
  short: 'FR',
  name: '法国',
  en: 'France',
  tel: '33'
}, {
  short: 'GA',
  name: '加蓬',
  en: 'Gabon',
  tel: '241'
}, {
  short: 'GB',
  name: '英国',
  en: 'UnitedKiongdom',
  tel: '44'
}, {
  short: 'GD',
  name: '格林纳达',
  en: 'Grenada',
  tel: '1809'
}, {
  short: 'GE',
  name: '格鲁吉亚',
  en: 'Georgia',
  tel: '995'
}, {
  short: 'GF',
  name: '法属圭亚那',
  en: 'FrenchGuiana',
  tel: '594'
}, {
  short: 'GH',
  name: '加纳',
  en: 'Ghana',
  tel: '233'
}, {
  short: 'GI',
  name: '直布罗陀',
  en: 'Gibraltar',
  tel: '350'
}, {
  short: 'GM',
  name: '冈比亚',
  en: 'Gambia',
  tel: '220'
}, {
  short: 'GN',
  name: '几内亚',
  en: 'Guinea',
  tel: '224'
}, {
  short: 'GR',
  name: '希腊',
  en: 'Greece',
  tel: '30'
}, {
  short: 'GT',
  name: '危地马拉',
  en: 'Guatemala',
  tel: '502'
}, {
  short: 'GU',
  name: '关岛',
  en: 'Guam',
  tel: '1671'
}, {
  short: 'GY',
  name: '圭亚那',
  en: 'Guyana',
  tel: '592'
}, {
  short: 'HK',
  name: '香港特别行政区',
  en: 'Hongkong',
  tel: '852'
}, {
  short: 'HN',
  name: '洪都拉斯',
  en: 'Honduras',
  tel: '504'
}, {
  short: 'HT',
  name: '海地',
  en: 'Haiti',
  tel: '509'
}, {
  short: 'HU',
  name: '匈牙利',
  en: 'Hungary',
  tel: '36'
}, {
  short: 'ID',
  name: '印度尼西亚',
  en: 'Indonesia',
  tel: '62'
}, {
  short: 'IE',
  name: '爱尔兰',
  en: 'Ireland',
  tel: '353'
}, {
  short: 'IL',
  name: '以色列',
  en: 'Israel',
  tel: '972'
}, {
  short: 'IN',
  name: '印度',
  en: 'India',
  tel: '91'
}, {
  short: 'IQ',
  name: '伊拉克',
  en: 'Iraq',
  tel: '964'
}, {
  short: 'IR',
  name: '伊朗',
  en: 'Iran',
  tel: '98'
}, {
  short: 'IS',
  name: '冰岛',
  en: 'Iceland',
  tel: '354'
}, {
  short: 'IT',
  name: '意大利',
  en: 'Italy',
  tel: '39'
}, {
  short: '',
  name: '科特迪瓦',
  en: 'IvoryCoast',
  tel: '225'
}, {
  short: 'JM',
  name: '牙买加',
  en: 'Jamaica',
  tel: '1876'
}, {
  short: 'JO',
  name: '约旦',
  en: 'Jordan',
  tel: '962'
}, {
  short: 'JP',
  name: '日本',
  en: 'Japan',
  tel: '81'
}, {
  short: 'KE',
  name: '肯尼亚',
  en: 'Kenya',
  tel: '254'
}, {
  short: 'KG',
  name: '吉尔吉斯坦',
  en: 'Kyrgyzstan',
  tel: '331'
}, {
  short: 'KH',
  name: '柬埔寨',
  en: 'Kampuchea(Cambodia)',
  tel: '855'
}, {
  short: 'KP',
  name: '朝鲜',
  en: 'NorthKorea',
  tel: '850'
}, {
  short: 'KR',
  name: '韩国',
  en: 'Korea',
  tel: '82'
}, {
  short: 'KT',
  name: '科特迪瓦共和国',
  en: 'RepublicofIvoryCoast',
  tel: '225'
}, {
  short: 'KW',
  name: '科威特',
  en: 'Kuwait',
  tel: '965'
}, {
  short: 'KZ',
  name: '哈萨克斯坦',
  en: 'Kazakstan',
  tel: '327'
}, {
  short: 'LA',
  name: '老挝',
  en: 'Laos',
  tel: '856'
}, {
  short: 'LB',
  name: '黎巴嫩',
  en: 'Lebanon',
  tel: '961'
}, {
  short: 'LC',
  name: '圣卢西亚',
  en: 'St.Lucia',
  tel: '1758'
}, {
  short: 'LI',
  name: '列支敦士登',
  en: 'Liechtenstein',
  tel: '423'
}, {
  short: 'LK',
  name: '斯里兰卡',
  en: 'SriLanka',
  tel: '94'
}, {
  short: 'LR',
  name: '利比里亚',
  en: 'Liberia',
  tel: '231'
}, {
  short: 'LS',
  name: '莱索托',
  en: 'Lesotho',
  tel: '266'
}, {
  short: 'LT',
  name: '立陶宛',
  en: 'Lithuania',
  tel: '370'
}, {
  short: 'LU',
  name: '卢森堡',
  en: 'Luxembourg',
  tel: '352'
}, {
  short: 'LV',
  name: '拉脱维亚',
  en: 'Latvia',
  tel: '371'
}, {
  short: 'LY',
  name: '利比亚',
  en: 'Libya',
  tel: '218'
}, {
  short: 'MA',
  name: '摩洛哥',
  en: 'Morocco',
  tel: '212'
}, {
  short: 'MC',
  name: '摩纳哥',
  en: 'Monaco',
  tel: '377'
}, {
  short: 'MD',
  name: '摩尔多瓦',
  en: 'Moldova,Republicof',
  tel: '373'
}, {
  short: 'MG',
  name: '马达加斯加',
  en: 'Madagascar',
  tel: '261'
}, {
  short: 'ML',
  name: '马里',
  en: 'Mali',
  tel: '223'
}, {
  short: 'MM',
  name: '缅甸',
  en: 'Burma',
  tel: '95'
}, {
  short: 'MN',
  name: '蒙古',
  en: 'Mongolia',
  tel: '976'
}, {
  short: 'MO',
  name: '澳门',
  en: 'Macao',
  tel: '853'
}, {
  short: 'MS',
  name: '蒙特塞拉特岛',
  en: 'MontserratIs',
  tel: '1664'
}, {
  short: 'MT',
  name: '马耳他',
  en: 'Malta',
  tel: '356'
}, {
  short: '',
  name: '马里亚那群岛',
  en: 'MarianaIs',
  tel: '1670'
}, {
  short: '',
  name: '马提尼克',
  en: 'Martinique',
  tel: '596'
}, {
  short: 'MU',
  name: '毛里求斯',
  en: 'Mauritius',
  tel: '230'
}, {
  short: 'MV',
  name: '马尔代夫',
  en: 'Maldives',
  tel: '960'
}, {
  short: 'MW',
  name: '马拉维',
  en: 'Malawi',
  tel: '265'
}, {
  short: 'MX',
  name: '墨西哥',
  en: 'Mexico',
  tel: '52'
}, {
  short: 'MY',
  name: '马来西亚',
  en: 'Malaysia',
  tel: '60'
}, {
  short: 'MZ',
  name: '莫桑比克',
  en: 'Mozambique',
  tel: '258'
}, {
  short: 'NA',
  name: '纳米比亚',
  en: 'Namibia',
  tel: '264'
}, {
  short: 'NE',
  name: '尼日尔',
  en: 'Niger',
  tel: '977'
}, {
  short: 'NG',
  name: '尼日利亚',
  en: 'Nigeria',
  tel: '234'
}, {
  short: 'NI',
  name: '尼加拉瓜',
  en: 'Nicaragua',
  tel: '505'
}, {
  short: 'NL',
  name: '荷兰',
  en: 'Netherlands',
  tel: '31'
}, {
  short: 'NO',
  name: '挪威',
  en: 'Norway',
  tel: '47'
}, {
  short: 'NP',
  name: '尼泊尔',
  en: 'Nepal',
  tel: '977'
}, {
  short: '',
  name: '荷属安的列斯',
  en: 'NetheriandsAntilles',
  tel: '599'
}, {
  short: 'NR',
  name: '瑙鲁',
  en: 'Nauru',
  tel: '674'
}, {
  short: 'NZ',
  name: '新西兰',
  en: 'NewZealand',
  tel: '64'
}, {
  short: 'OM',
  name: '阿曼',
  en: 'Oman',
  tel: '968'
}, {
  short: 'PA',
  name: '巴拿马',
  en: 'Panama',
  tel: '507'
}, {
  short: 'PE',
  name: '秘鲁',
  en: 'Peru',
  tel: '51'
}, {
  short: 'PF',
  name: '法属玻利尼西亚',
  en: 'FrenchPolynesia',
  tel: '689'
}, {
  short: 'PG',
  name: '巴布亚新几内亚',
  en: 'PapuaNewCuinea',
  tel: '675'
}, {
  short: 'PH',
  name: '菲律宾',
  en: 'Philippines',
  tel: '63'
}, {
  short: 'PK',
  name: '巴基斯坦',
  en: 'Pakistan',
  tel: '92'
}, {
  short: 'PL',
  name: '波兰',
  en: 'Poland',
  tel: '48'
}, {
  short: 'PR',
  name: '波多黎各',
  en: 'PuertoRico',
  tel: '1787'
}, {
  short: 'PT',
  name: '葡萄牙',
  en: 'Portugal',
  tel: '351'
}, {
  short: 'PY',
  name: '巴拉圭',
  en: 'Paraguay',
  tel: '595'
}, {
  short: 'QA',
  name: '卡塔尔',
  en: 'Qatar',
  tel: '974'
}, {
  short: '',
  name: '留尼旺',
  en: 'Reunion',
  tel: '262'
}, {
  short: 'RO',
  name: '罗马尼亚',
  en: 'Romania',
  tel: '40'
}, {
  short: 'RU',
  name: '俄罗斯',
  en: 'Russia',
  tel: '7'
}, {
  short: 'SA',
  name: '沙特阿拉伯',
  en: 'SaudiArabia',
  tel: '966'
}, {
  short: 'SB',
  name: '所罗门群岛',
  en: 'SolomonIs',
  tel: '677'
}, {
  short: 'SC',
  name: '塞舌尔',
  en: 'Seychelles',
  tel: '248'
}, {
  short: 'SD',
  name: '苏丹',
  en: 'Sudan',
  tel: '249'
}, {
  short: 'SE',
  name: '瑞典',
  en: 'Sweden',
  tel: '46'
}, {
  short: 'SG',
  name: '新加坡',
  en: 'Singapore',
  tel: '65'
}, {
  short: 'SI',
  name: '斯洛文尼亚',
  en: 'Slovenia',
  tel: '386'
}, {
  short: 'SK',
  name: '斯洛伐克',
  en: 'Slovakia',
  tel: '421'
}, {
  short: 'SL',
  name: '塞拉利昂',
  en: 'SierraLeone',
  tel: '232'
}, {
  short: 'SM',
  name: '圣马力诺',
  en: 'SanMarino',
  tel: '378'
}, {
  short: '',
  name: '东萨摩亚(美)',
  en: 'SamoaEastern',
  tel: '684'
}, {
  short: '',
  name: '西萨摩亚',
  en: 'SanMarino',
  tel: '685'
}, {
  short: 'SN',
  name: '塞内加尔',
  en: 'Senegal',
  tel: '221'
}, {
  short: 'SO',
  name: '索马里',
  en: 'Somali',
  tel: '252'
}, {
  short: 'SR',
  name: '苏里南',
  en: 'Suriname',
  tel: '597'
}, {
  short: 'ST',
  name: '圣多美和普林西比',
  en: 'SaoTomeandPrincipe',
  tel: '239'
}, {
  short: 'SV',
  name: '萨尔瓦多',
  en: 'EISalvador',
  tel: '503'
}, {
  short: 'SY',
  name: '叙利亚',
  en: 'Syria',
  tel: '963'
}, {
  short: 'SZ',
  name: '斯威士兰',
  en: 'Swaziland',
  tel: '268'
}, {
  short: 'TD',
  name: '乍得',
  en: 'Chad',
  tel: '235'
}, {
  short: 'TG',
  name: '多哥',
  en: 'Togo',
  tel: '228'
}, {
  short: 'TH',
  name: '泰国',
  en: 'Thailand',
  tel: '66'
}, {
  short: 'TJ',
  name: '塔吉克斯坦',
  en: 'Tajikstan',
  tel: '992'
}, {
  short: 'TM',
  name: '土库曼斯坦',
  en: 'Turkmenistan',
  tel: '993'
}, {
  short: 'TN',
  name: '突尼斯',
  en: 'Tunisia',
  tel: '216'
}, {
  short: 'TO',
  name: '汤加',
  en: 'Tonga',
  tel: '676'
}, {
  short: 'TR',
  name: '土耳其',
  en: 'Turkey',
  tel: '90'
}, {
  short: 'TT',
  name: '特立尼达和多巴哥',
  en: 'TrinidadandTobago',
  tel: '1809'
}, {
  short: 'TW',
  name: '台湾省',
  en: 'Taiwan',
  tel: '886'
}, {
  short: 'TZ',
  name: '坦桑尼亚',
  en: 'Tanzania',
  tel: '255'
}, {
  short: 'UA',
  name: '乌克兰',
  en: 'Ukraine',
  tel: '380'
}, {
  short: 'UG',
  name: '乌干达',
  en: 'Uganda',
  tel: '256'
}, {
  short: 'US',
  name: '美国',
  en: 'UnitedStatesofAmerica',
  tel: '1'
}, {
  short: 'UY',
  name: '乌拉圭',
  en: 'Uruguay',
  tel: '598'
}, {
  short: 'UZ',
  name: '乌兹别克斯坦',
  en: 'Uzbekistan',
  tel: '233'
}, {
  short: 'VC',
  name: '圣文森特岛',
  en: 'SaintVincent',
  tel: '1784'
}, {
  short: 'VE',
  name: '委内瑞拉',
  en: 'Venezuela',
  tel: '58'
}, {
  short: 'VN',
  name: '越南',
  en: 'Vietnam',
  tel: '84'
}, {
  short: 'YE',
  name: '也门',
  en: 'Yemen',
  tel: '967'
}, {
  short: 'YU',
  name: '南斯拉夫',
  en: 'Yugoslavia',
  tel: '381'
}, {
  short: 'ZA',
  name: '南非',
  en: 'SouthAfrica',
  tel: '27'
}, {
  short: 'ZM',
  name: '赞比亚',
  en: 'Zambia',
  tel: '260'
}, {
  short: 'ZR',
  name: '扎伊尔',
  en: 'Zaire',
  tel: '243'
}, {
  short: 'ZW',
  name: '津巴布韦',
  en: 'Zimbabwe',
  tel: '263'
}])
const pagelist = ['0', '1', '2']
const form = ref({
  uname: '',
  phone: '',
  email: '',
  career: '',
  business: ''
})
const timer = ref(null)
// 屏幕宽度
// const windowWidth = ref(0)
// // 屏幕高度
// const windowHeight = ref(0)
const submit = () => {
  if (form.value.uname === '') {
    ElMessage.error('please input your information')
  } else if (form.value.phone === '') {
    ElMessage.error('Please enter your phonenumber')
  } else if (form.value.email === '') {
    ElMessage.error('please input your email')
  // eslint-disable-next-line no-useless-escape
  } else if (form.value.email.search(/^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/) === -1) {
    // eslint-disable-next-line no-useless-escape
    ElMessage.error('please enter your correct email')
  } else {
    axios({
      method: 'post',
      url: 'api/web/api/addClientD',
      data: {
        name: form.value.uname,
        phone: phonecode.value + form.value.phone,
        email: form.value.email,
        career: form.value.career,
        business: form.value.business
      }
    }).then((res) => {
      // console.log(res)
    })
    submitform.value = true
  }
}
const getcarvalue = (index, item) => {
  ifcarshow.value = false
  form.value.career = item.type
}
const getbusvalue = (index, item) => {
  ifbusshow.value = false
  form.value.business = item.type
}
const openValue = () => {
  ifcarshow.value = !ifcarshow.value
  if (ifbusshow.value === true) {
    ifbusshow.value = false
  }
}
const openbusValue = () => {
  ifbusshow.value = !ifbusshow.value
  if (ifcarshow.value === true) {
    ifcarshow.value = false
  }
}
const leave = () => {
  clearInterval(timer.value)
  timer.value = null
  timer.value = setInterval(() => {
    lunbo()
  }, 7500)
}
const lunbo = () => {
  if (activeVar.value <= 2) {
    activeVar.value += 1
  }
  if (activeVar.value === 3) {
    activeVar.value = 0
  }
}
const enter = () => {
  clearInterval(timer.value)
  timer.value = null
}

onMounted(() => {
  leave()
})
onBeforeUnmount(() => {
  clearInterval(timer.value)
  timer.value = null
})
// 生命周期
// onMounted(() => {
//   getWindowResize()
//   window.addEventListener('resize', getWindowResize)
// })

// // 获取屏幕尺寸
// const getWindowResize = function () {
//   windowWidth.value = window.innerWidth
//   windowHeight.value = window.innerHeight
//   // console.log(windowHeight)
// }

const activeFun = (item, index) => {
  activeVar.value = index
  clearInterval(timer.value)
  timer.value = null
  timer.value = setInterval(() => {
    lunbo()
  }, 7500)
}
const turnleft = () => {
  if (activeVar.value < 3 && activeVar.value > 0) {
    activeVar.value -= 1
  } else if (activeVar.value === 0) {
    activeVar.value = 2
  }
  clearInterval(timer.value)
  timer.value = null
  timer.value = setInterval(() => {
    lunbo()
  }, 7500)
}
const turnright = () => {
  if (activeVar.value >= 0 && activeVar.value < 2) {
    activeVar.value += 1
  } else if (activeVar.value === 2) {
    activeVar.value = 0
  }
  clearInterval(timer.value)
  timer.value = null
  timer.value = setInterval(() => {
    lunbo()
  }, 7500)
}
</script>
<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
html,body {
  height: 100%;
        }
::-webkit-scrollbar {
/*隐藏滚轮*/
  display: none;
}
.it-search{
  display: block;
  position: relative;
  /* margin-bottom: 28px; */
  /* bottom: 20px; */
  padding: 2px 12px;
  align-items: center;
  align-self: stretch;
  height: 24px;
  width: 130px;
  margin: 4px 4px 6px 8px;
  z-index: 1000;
  outline: none;
  border: solid 1px #2B2E3799;
}
.it-select {
    display: flex;
    border-right: 1px solid rgba(0, 22, 93, 0.2);
    position: relative;
    display: flex;
    width: 70px;
    height: 30px;
    align-items: center;
    border-radius: 0%;
    // border-radius: 4px;
    // border: 0.5px solid var(--grey-color-ebony-ebony-40, rgba(43, 46, 55, 0.40));
    // background: var(--grey-color-ghost-white-ghost-white-100, #FBFCFF);
    /* Shadow/lg */
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    .uparrow{
      height: 16px;
      padding-right: 10px;
      width: 16px;
    }
  }
.it-select-input {
  width: calc(100% - 20px);
  /* height: 100%; */
  font-size: 12px;
  /* line-height: 100%; */
  text-align: center;
  cursor: pointer;
  border: none;
  padding-left: 4px;
  display: flex;
  align-items: center;
}
.it-select-input:empty::before{
  content: "区号";
  font-size: 16px;
  color: #2b2e37;
  opacity: 0.6;
}
// .triangle-down {
//   width: 0;
//   height: 0;
//   border-left: 8px solid transparent;
//   border-right: 8px solid transparent;
//   border-top: 6px solid rgba(201, 201, 201, 1);
//   position: absolute;
//   top: 50%;
//   right: 15px;
//   transform: translateY(-50%) rotate(0deg);
//   transition: transform 0.3s ease-in-out;
// }
// .rotate {
//   transform: translateY(-50%) rotate(180deg);
// }
.it-select-options-panel {
  position: absolute;
  width: 180px;
  height: 560px;
  top: calc(100% + 4px);
  left: 0;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(32, 110, 242, 0.15);
  border-radius: 10px;
  max-height: 264px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10;
}
.it-select-option {
  color: var(--grey-color-ebony-ebony-80, rgba(43, 46, 55, 0.80));
  font-family: 'Outfit-Regular';
  font-size: 12px;
  font-weight: 500;
  /* margin-top: 28px; */
  display: flex;
  padding: 2px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  cursor: pointer;
  height: 24px;
  /* line-height: 44px; */
  color: #333333;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.it-select-option:hover{
  background-color: #cccccc;
}
.show {
  display: block;
}
.hidden {
  display: none;
}
.countryselect{
  background-color: rgba($color: #000000, $alpha: 0);
  border: none;
}
.option :hover{
  background-color: 0029FF;
  opacity: 0.2;
}
.parallax > use {
  animation: move-forever 25s cubic-bezier(.9,.5,.45,.5)  infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -1s;
  animation-duration: 12s;
}
.parallax > use:nth-child(2) {
  animation-delay: -2s;
  animation-duration: 15s;
}
.parallax > use:nth-child(3) {
  animation-delay: -3s;
  animation-duration: 17s;
}
.parallax > use:nth-child(4) {
  animation-delay: -4s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
   transform: translate3d(-90px,0,0);
  }
  100% {
    transform: translate3d(85px,0,0);
  }
}
@-webkit-keyframes move-forever {
  0% {
   transform: translate3d(-90px,0,0);
  }
  100% {
    transform: translate3d(85px,0,0);
  }
}
.wavebg{
  display: flex;
  width: 100%;
  position: fixed;
  bottom: 0;
  .waves {
    width: 100%;
    height:80vh;
    min-height:100px;
    max-height:420px;
  }
}
.background{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;;
    align-content: center;
    // flex-wrap: wrap;
    bottom: 0;
    // background:url('@/assets/images/bg.png');
}
.bg{
  z-index: 2;
  width: 640px;
  height: 100%;
  background: var(--grey-color-ghost-white-ghost-white-40, rgba(251, 252, 255, 0.40));
  -webkit-backdrop-filter:blur(30px);
  backdrop-filter: blur(30px);
}
.element::-webkit-scrollbar {display:none}

.page3{
  // overflow: hidden;
  height: 100%;
  width: 576px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 0px 48px 0px;
  gap: 16px;
  align-self: stretch;
  flex: 1 0 0;
  animation: fade 1s;
  .afterform{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    animation: fade 1s;
    .aftertext{
      color: var(--functional-colors-primary-primary-100, #0029FF);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    /* Chinese Mode/Bold Title32 */
    font-family: "NotoSansSC-Regular";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 125% */
    }
  }
  .contactbox{
    text-align: left;
    display: flex;
    // width: 100%;
    padding: 0px 140px;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 4px;
    align-self: stretch;
    flex-wrap: wrap;
  }
  .ban{
    width: 27.4px;
    height: 48px;
    flex-shrink: 0;
  }
  .contact{
    width: 320px;
    display: flex;
    align-items: center;
    gap: 20px;
  .chinese{
    color: var(--grey-color-ebony-ebony-100, #2B2E37);
    font-feature-settings: 'clig' off, 'liga' off;
    /* Chinese Mode Sub-Font/Title32 */
    font-family:"NotoSerifSC-Regular";
    font-size: 20px;
    font-weight: 700;
    // line-height: 40px; /* 125% */
    }
    .chi2{
      color: var(--grey-color-ebony-ebony-100, #2B2E37);
      font-feature-settings: 'clig' off, 'liga' off;
      /* Chinese Mode/Medium Title20 */
      font-family: "NotoSansSC-Regular";
      font-size: 12px;
      font-weight: 500;
      // line-height: 28px;
    }
    .english{
      color: var(--grey-color-ebony-ebony-100, #2B2E37);
      font-feature-settings: 'clig' off, 'liga' off;
      /* English Mode Sub-Font/Bold Title32 */
      font-family: 'BonaNova-Regular';
      font-size: 20px;
      font-weight: 700;
      // line-height: 40px; /* 125% */
    }
    .eng2{
      color: var(--grey-color-ebony-ebony-100, #2B2E37);
      font-feature-settings: 'clig' off, 'liga' off;
      /* English Mode/Title20 */
      font-family: 'Outfit-Regular';
      font-size: 12px;
      font-weight: 500;
      // line-height: 28px;
    }
  }
  .e-mail{
    margin-top: 0px;
    text-align: left;
    width: 320px;
  .e-title{
      color: var(--grey-color-ebony-ebony-100, #2B2E37);
      font-feature-settings: 'clig' off, 'liga' off;
      /* English Mode/Title24 */
      font-family: 'Outfit-Regular';
      font-size: 16px;
      font-weight: 500;
      // line-height: 32px; /* 133.333% */
    }
    .e-tail{
      color: var(--grey-color-ebony-ebony-100, #2B2E37);
      font-feature-settings: 'clig' off, 'liga' off;
      /* English Mode/Regular Title24 */
      font-family: 'Outfit-Regular';
      font-size: 16px;
      font-weight: 400;
      // line-height: 32px;
    }
  }
  .formbox{
    height: 100%;
    overflow: hidden;
    display: flex;
    // padding: 32px 20px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    .form{
      overflow-y: scroll;
      height: 100%;
      background: var(--grey-color-ghost-white-ghost-white-40, rgba(251, 252, 255, 0.40));
      // -webkit-backdrop-filter:blur(30px);
      // backdrop-filter: blur(30px);
      padding: 0px 20px;
      width: 360px;
      display: flex;
      align-items:center;
      border-radius: 8px;
      flex-direction: column;
      gap: 24px;
      .button{
        display: flex;
        margin-top: -8px;
        padding-bottom: 16px;
        .submit{
          cursor: pointer;
          font-weight: 700;
          border: none;
          color: #FBFCFF;
          display: flex;
          padding: 12px 24px;
          gap: 10px;
          border-radius: 24px;
          background: var(--functional-colors-primary-primary-80, rgba(0, 41, 255, 0.80));
          }
      }

      .text{
        margin-top: 4px;
        display: flex;
        height: 64px;
        flex-direction: column;
        justify-content: center;
        align-self: stretch;
        color: var(--functional-colors-primary-primary-100, #0029FF);
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;

        /* Chinese Mode/Bold Title32 */
        font-family: 'NotoSansSC-Regular';
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        // line-height: 24px; /* 125% */
      }
      .formitem2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        display: flex;
        gap: 8px;
        // height: 140px;
        // overflow-x: hidden;
        width: 360px;
        .inputemail{
          // margin-top: 8px;
          font-size: 16px;
          border-radius: 4px;
          border: 0.5px solid var(--grey-color-ebony-ebony-40, rgba(43, 46, 55, 0.40));
        }
        .formlabel{
          color: var(--grey-color-ebony-ebony-100, #2B2E37);

          /* Chinese Mode/Bold Title24 */
          font-family: 'NotoSansSC-Regular';
          font-size: 16px;
          font-weight: 700;
          line-height: 32px; /* 133.333% */
        }
        .forminput{
          border: 0;
          padding: 4px;
          width: 352px;
          height: 24px;
          flex-shrink: 0;
          outline: none;
          font-size: 16px;
          // margin-top: 24px;
          background-color: rgba($color: #000000, $alpha: 0);
        }
        .inputphone{
          // margin-top: 8px;
          display: flex;
          align-items: center;
          font-size: 16px;
          border-radius: 4px;
          border: 0.5px solid var(--grey-color-ebony-ebony-40, rgba(43, 46, 55, 0.40));
          .forminput{
            border: 0;
            padding: 4px;
            width: 281px;
            height: 24px;
            flex-shrink: 0;
            outline: none;
            font-size: 16px;
            // margin-top: 24px;
            background-color: rgba($color: #000000, $alpha: 0);
          }
        }
      }
      .formitem3{
        // margin-top: -3px;
        // height: 74px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        display: flex;
        gap: 8px;
        // height: 140px;
        width: 360px;
        .formlabel{
          // margin-top: 24px;
          color: var(--grey-color-ebony-ebony-100, #2B2E37);

          /* Chinese Mode/Bold Title24 */
          font-family: 'NotoSansSC-Regular';
          font-size: 16px;
          font-weight: 700;
          line-height: 32px; /* 133.333% */
        }
        .divforminput{
          // margin-top: 24px;
          display: flex;
          align-items: center;
          outline: none;
          width: 360px;
          border-radius: 4px;
          border: 0.5px solid var(--grey-color-ebony-ebony-40, rgba(43, 46, 55, 0.40));
          flex-shrink: 0;
          background-color: rgba($color: #000000, $alpha: 0);
          .forminput{
          padding: 4px;
          font-size: 16px;
          width: 324px;
          border: 0;
          height: 24px;
          flex-shrink: 0;
          outline: none;
          background-color: rgba($color: #000000, $alpha: 0);
          }
        }
        .list{
          padding-top:8px ;
          position: absolute;
          width: 100%;
          height: 200px;
          top: calc(100%);
          left: 0;
          background: #ffffff;
          box-shadow: 0px 0px 15px rgba(32, 110, 242, 0.15);
          border-radius: 10px;
          max-height: 264px;
          overflow-y: auto;
          overflow-x: hidden;
          z-index: 10;
        }
         .list ul li{
          color: var(--grey-color-ebony-ebony-80, rgba(43, 46, 55, 0.80));
          font-family: 'Outfit-Regular';
          font-size: 14px;
          font-weight: 500;
          /* margin-top: 28px; */
          display: flex;
          padding: 2px 12px;
          align-items: center;
          gap: 8px;
          align-self: stretch;
          cursor: pointer;
          height: 24px;
          /* line-height: 44px; */
          color: #333333;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          }
          .list ul li:hover{
            background-color: #cccccc;
          }
        .uparrow{
          height: 20px;
          width: 20px;
        }
      }
      .formitem1{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        display: flex;
        gap: 8px;
        // height: 140px;
        width: 360px;
        .formlabel{
          // margin-top: 24px;
          color: var(--grey-color-ebony-ebony-100, #2B2E37);

          /* Chinese Mode/Bold Title24 */
          font-family: 'NotoSansSC-Regular';
          font-size: 16px;
          font-weight: 700;
          line-height: 32px; /* 133.333% */
        }
        .divforminput{
          // margin-top: 24px;
          display: flex;
          align-items: center;
          outline: none;
          width: 360px;
          border-radius: 4px;
          border: 0.5px solid var(--grey-color-ebony-ebony-40, rgba(43, 46, 55, 0.40));
          flex-shrink: 0;
          background-color: rgba($color: #000000, $alpha: 0);
          .forminput{
          padding: 4px;
          font-size: 16px;
          width: 324px;
          border: 0;
          height: 24px;
          flex-shrink: 0;
          outline: none;
          background-color: rgba($color: #000000, $alpha: 0);
          }
        }
        .list{
          padding-top:8px ;
          position: absolute;
          width: 100%;
          height: 120px;
          top: calc(100%);
          left: 0;
          background: #ffffff;
          box-shadow: 0px 0px 15px rgba(32, 110, 242, 0.15);
          border-radius: 10px;
          max-height: 264px;
          overflow-y: auto;
          overflow-x: hidden;
          z-index: 10;
        }
         .list ul li{
          color: var(--grey-color-ebony-ebony-80, rgba(43, 46, 55, 0.80));
          font-family: 'Outfit-Regular';
          font-size: 14px;
          font-weight: 500;
          /* margin-top: 28px; */
          display: flex;
          padding: 2px 12px;
          align-items: center;
          gap: 8px;
          align-self: stretch;
          cursor: pointer;
          height: 24px;
          /* line-height: 44px; */
          color: #333333;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          }
          .list ul li:hover{
            background-color: #cccccc;
          }
        .uparrow{
          height: 20px;
          width: 20px;
        }
      }
      .formitem0{
        // height: 84px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        display: flex;
        gap: 8px;
        // height: 140px;
        width: 340px;
        .formlabel{
          color: var(--grey-color-ebony-ebony-100, #2B2E37);

          /* Chinese Mode/Bold Title24 */
          font-family: 'NotoSansSC-Regular';
          font-size: 16px;
          font-weight: 700;
          line-height: 32px; /* 133.333% */
        }
        .divforminput{
          // margin-top: 24px;
          display: flex;
          align-items: center;
          outline: none;
          width: 360px;
          border-radius: 4px;
          border: 0.5px solid var(--grey-color-ebony-ebony-40, rgba(43, 46, 55, 0.40));
          flex-shrink: 0;
          background-color: rgba($color: #000000, $alpha: 0);
          .forminput{
          padding: 4px;
          font-size: 16px;
          width: 354px;
          border: 0;
          height: 24px;
          flex-shrink: 0;
          outline: none;
          background-color: rgba($color: #000000, $alpha: 0);
          }
        }
     }
    }
  }
}
.mobilogo{
      display: flex;
      width: 168px;
      height: 64px;
      padding-right: 0px;
      align-items: center;
      gap: 8px;
      flex-shrink: 0;
  }
.page2{
  height: 100%;
  display: flex;
  padding: 56px 0px 48px 0px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
  animation: fade 1s;
    .text1{
      align-self: stretch;
      color: var(--grey-color-ebony-ebony-100, #2B2E37);
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: "NotoSerifSC-Regular";
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      // line-height: 32px; /* 133.333% */
    }
    .text2{
      align-self: stretch;
      color: var(--grey-color-ebony-ebony-100, #2B2E37);
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family:"BonaNova-Regular";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      // line-height: 32px; /* 160% */
    }
  .introduce{
    height: 100%;
    display: flex;
    width: 576px;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: center;
    // align-content: center;
    text-align: left;
    gap: 8px;
    // flex: 1 0 0;
    flex-wrap: wrap;
    .box{
      // text-align: left;
      display: flex;
      width: 260px;
      height: 30%;
      align-items: center;
      // flex-shrink: 0;
      // justify-content: center;
      //   align-content: center;
      border-radius: 8px;
      background: linear-gradient(0deg, var(--functional-colors-primary-primary-5, rgba(0, 41, 255, 0.05)) 0%, var(--functional-colors-primary-primary-5, rgba(0, 41, 255, 0.05)) 100%), var(--grey-color-ghost-white-ghost-white-40, rgba(251, 252, 255, 0.40));
      .global{
        padding-left: 8px;
        display: flex;
        width: 120px;
        // height: 50%;
        // height: 30%;
        // padding: 37.5px 15.224px 37.55px 17.501px
        flex-shrink: 0;
      }
      .global img {
        height: 100px;
         width: 100px;
      }
      .gtext1{
        width: 100%;
        color: var(--grey-color-ebony-ebony-100, #2B2E37);
        // text-align: left;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: "Outfit-Regular";
        font-size: 12px;
        font-weight: 700;
        line-height: normal;
      }
      .gtext2{
        color: var(--grey-color-ebony-ebony-100, #2B2E37);
        // text-align: left;
        text-align: left;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: "NOtoSansSC-Regular";
        font-size: 12px;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
}
.page1{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  flex: 1 0 0;
  align-self: stretch;
  animation: fade 1s;
  width: 100%;
  .coming{
  color: var(--brand-color-rich-blue-rich-blue-100, #0029FF);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'BonaNova-Regular';
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 62.5% */
}
  .soon{
    color: var(--grey-color-ebony-ebony-100, #2B2E37);
    font-family: 'NotoSansSC-Regular';
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
  }
}
.select{
  display: flex;
  align-items: center;
  gap: 16px;
  .rectangle{
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    background: var(--grey-color-ghost-white-ghost-white-100, #FBFCFF);
    transition: transform 1s;
    cursor: pointer;
  }
  .activerec{
    cursor: pointer;
    width: 20px;
    height: 20px;
    transform: rotate(-135deg);
    background: var(--functional-colors-primary-primary-100, #0029FF);
    transition: transform 1s;
    animation: fade 1s;
  }

}
// /*screen>1920 */
@media screen and (min-width: 1920px){
  .content{
    height: 100%;
    width: 576px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-self: stretch;
  }
    .layout{
    gap: 16px;
    display: flex;
    width: 576px;
    padding: 32px;
    height: 91%;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
  }
}

// // /*1200<screen<1920 */
@media screen and (min-width:1200px) and (max-width:1919px){
  .content{
    height: 100%;
    width: 576px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-self: stretch;
  }
    .layout{
    gap: 16px;
    display: flex;
    width: 576px;
    padding: 32px;
    height: 91%;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
  }
}

// /*922<screen<1200 */
@media screen and (min-width: 922px) and (max-width:1199px){
  .content{
    height: 100%;
    width: 576px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-self: stretch;
  }
    .layout{
    gap: 16px;
    display: flex;
    width: 576px;
    padding: 32px;
    height: 91%;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
  }
}

// // /*640<screen<922 */
@media screen and (min-width: 801px) and (max-width:922px){

  .content{
    height: 100%;
    width: 576px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-self: stretch;
  }
    .layout{
    gap: 16px;
    display: flex;
    width: 576px;
    padding: 32px;
    height: 91%;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
  }
}

// // /*screenwidth<800*/
@media screen and (max-width:800px){
    .mobilogo{
      display: flex;
      width: 88px;
      height: 32px;
      padding-right: 0px;
      align-items: center;
      gap: 4px;
      flex-shrink: 0;
  }
  .content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-self: stretch;
    height: 100%;
    width: 100%;
  }
    .layout{
      display: flex;
      width: 100%;
      height:91%;
      // padding: 48px;
      padding-top: 48px;
      padding-bottom: 48px;
      flex-direction: column;
      align-items: center;
      flex-shrink: 0;
  }
  .page1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
    animation: fade 1s;
    width: 100%;
  .coming{
  color: var(--brand-color-rich-blue-rich-blue-100, #0029FF);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'BonaNova-Regular';
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 62.5% */
}
  .soon{
    color: var(--grey-color-ebony-ebony-100, #2B2E37);
    font-family: 'NotoSansSC-Regular';
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
  }
}
  .page2{
    height: 100%;
    display: flex;
    padding: 32px 0px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
    .text1{
      font-family: "NotoSerifSC-Regular";
      color: var(--grey-color-ebony-ebony-100, #2B2E37);
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      // line-height: 20px; /* 125% */
    }
    .text2{
      font-feature-settings: 'clig' off, 'liga' off;
      font-family:"BonaNova-Regular";
      color: var(--grey-color-ebony-ebony-100, #2B2E37);
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 10px;
      font-weight: 400;
      // line-height: 16px; /* 133.333% */
    }
      .introduce{
      width: 90%;
      height: 100%;
      display: flex;
      align-items: flex-start;
      align-content: flex-start;
      justify-content: center;
      // align-content: center;
      gap: 8px;
      // flex: 1 0 0;
      // flex-wrap: wrap;
      .box{
        // text-align: left;
        display: flex;
        width: 260px;
        height: 16%;
        align-items: center;
        // flex-shrink: 0;
        // justify-content: space-evenly;
        //   align-content: center;
        text-align: left;
        border-radius: 8px;
        background: linear-gradient(0deg, var(--functional-colors-primary-primary-5, rgba(0, 41, 255, 0.05)) 0%, var(--functional-colors-primary-primary-5, rgba(0, 41, 255, 0.05)) 100%), var(--grey-color-ghost-white-ghost-white-40, rgba(251, 252, 255, 0.40));
        .global{
          padding-left: 8px;
          display: flex;
          align-items: center;
          width: 120px;
          height: 100%;
          // height: 30%;
          // padding: 37.5px 15.224px 37.55px 17.501px
          flex-shrink: 0;
        }
        .global img {
          height: 64px;
           width: 64px;
        }
        .gtext1{
          width: 100%;
          color: var(--grey-color-ebony-ebony-100, #2B2E37);
          // text-align: left;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: "Outfit-Regular";
          font-size: 12px;
          font-weight: 700;
          line-height: normal;
        }
        .gtext2{
          color: var(--grey-color-ebony-ebony-100, #2B2E37);
          // text-align: left;
          text-align: left;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: "NOtoSansSC-Regular";
          font-size: 12px;
          font-weight: 700;
          line-height: normal;
        }
      }
    }
  }
  .page3{
  // overflow: hidden;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // padding: 40px 0px 40px 0px;
    gap: 16px;
    align-self: stretch;
    flex: 1 0 0;
    animation: fade 1s;
  .afterform{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    animation: fade 1s;
    .aftertext{
      color: var(--functional-colors-primary-primary-100, #0029FF);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    /* Chinese Mode/Bold Title32 */
    font-family: "NotoSansSC-Regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    // line-height: 32px; /* 125% */
    }
  }
  .contactbox{
    text-align: left;
    display: flex;
    // width: 100%;
    // padding: 0px 27px;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 4px;
    align-self: stretch;
    flex-wrap: wrap;
  }
  .ban{
    width: 27.4px;
    height: 48px;
    flex-shrink: 0;
  }
  .contact{
    display: flex;
    align-items: center;
    padding: 0 27px;
    justify-content:space-between;
    gap: 10px;
    // width: 272px;
  .chinese{
    color: var(--grey-color-ebony-ebony-100, #2B2E37);
    font-feature-settings: 'clig' off, 'liga' off;
    /* Chinese Mode Sub-Font/Title32 */
    font-family:"NotoSerifSC-Regular";
    font-size: 16px;
    font-weight: 700;
    // line-height: 40px; /* 125% */
    }
    .chi2{
      color: var(--grey-color-ebony-ebony-100, #2B2E37);
      font-feature-settings: 'clig' off, 'liga' off;
      /* Chinese Mode/Medium Title20 */
      font-family: "NotoSansSC-Regular";
      font-size: 10px;
      font-weight: 500;
      // line-height: 28px;
    }
    .english{
      color: var(--grey-color-ebony-ebony-100, #2B2E37);
      font-feature-settings: 'clig' off, 'liga' off;
      /* English Mode Sub-Font/Bold Title32 */
      font-family: 'BonaNova-Regular';
      font-size: 16px;
      font-weight: 700;
      // line-height: 40px; /* 125% */
    }
    .eng2{
      color: var(--grey-color-ebony-ebony-100, #2B2E37);
      font-feature-settings: 'clig' off, 'liga' off;
      /* English Mode/Title20 */
      font-family: 'Outfit-Regular';
      font-size: 10px;
      font-weight: 500;
      // line-height: 28px;
    }
  }
  .e-mail{
    display: flex;
    align-items: center;
    padding: 0 27px;
    // justify-content:center;
    gap: 10px;
    text-align: left;
  .e-title{
      color: var(--grey-color-ebony-ebony-100, #2B2E37);
      font-feature-settings: 'clig' off, 'liga' off;
      /* English Mode/Title24 */
      font-family: 'Outfit-Regular';
      font-size: 12px;
      font-weight: 500;
      // line-height: 32px; /* 133.333% */
    }
    .e-tail{
      color: var(--grey-color-ebony-ebony-100, #2B2E37);
      font-feature-settings: 'clig' off, 'liga' off;
      /* English Mode/Regular Title24 */
      font-family: 'Outfit-Regular';
      font-size: 12px;
      font-weight: 400;
      // line-height: 32px;
    }
  }
  .formbox{
    height: 100%;
    overflow: hidden;
    display: flex;
    // padding: 32px 20px;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    .form{
      overflow-x: hidden;
      overflow-y: scroll;
      height: 100%;
      background: var(--grey-color-ghost-white-ghost-white-40, rgba(251, 252, 255, 0.40));
      // -webkit-backdrop-filter:blur(30px);
      // backdrop-filter: blur(30px);
      padding: 0px 20px;
      width: 320px;
      display: flex;
      align-items:center;
      border-radius: 8px;
      flex-direction: column;
      gap: 24px;
      .button{
        display: flex;
        width: 100%;
        flex-direction: column;
        margin-top: -8px;
        padding-bottom: 16px;
        .submit{
          font-size: 12px;
          text-align: center;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          cursor: pointer;
          font-weight: 700;
          border: none;
          color: #FBFCFF;
          display: flex;
          padding: 12px 24px;
          gap: 10px;
          border-radius: 24px;
          background: var(--functional-colors-primary-primary-80, rgba(0, 41, 255, 0.80));
          }
      }
      .text{
        margin-top: 12px;
        display: flex;
        height: 64px;
        flex-direction: column;
        justify-content: center;
        align-self: stretch;
        color: var(--functional-colors-primary-primary-100, #0029FF);
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;

        /* Chinese Mode/Bold Title32 */
        font-family: 'NotoSansSC-Regular';
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        // line-height: 40px; /* 125% */
      }
      .formitem2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        display: flex;
        gap: 8px;
        // height: 140px;
        width: 320px;
        .inputemail{
          // margin-top: 8px;
          // width: 320px;
          font-size: 12px;
          border-radius: 4px;
          border: 0.5px solid var(--grey-color-ebony-ebony-40, rgba(43, 46, 55, 0.40));
        }
        .formlabel{
          color: var(--grey-color-ebony-ebony-100, #2B2E37);

          /* Chinese Mode/Bold Title24 */
          font-family: 'NotoSansSC-Regular';
          font-size: 12px;
          font-weight: 700;
          line-height: 32px; /* 133.333% */
        }
        .forminput{
          padding: 4px;
          border: 0;
          width: 312px;
          // height: 30px;
          flex-shrink: 0;
          outline: none;
          font-size: 12px;
          // margin-top: 24px;
          background-color: rgba($color: #000000, $alpha: 0);
        }
        .inputphone{
          // margin-top: 8px;
          display: flex;
          align-items: center;
          font-size: 12px;
          border-radius: 4px;
          border: 0.5px solid var(--grey-color-ebony-ebony-40, rgba(43, 46, 55, 0.40));
          .forminput{
            border: 0;
            padding: 4px;
            width: 260px;
            height: 24px;
            flex-shrink: 0;
            outline: none;
            font-size: 12px;
            // margin-top: 24px;
            background-color: rgba($color: #000000, $alpha: 0);
          }
        }
        .it-select {
            border-right: 1px solid rgba(0, 22, 93, 0.2);
            position: relative;
            display: flex;
            width: 51px;
            height: 30px;
            align-items: center;
            border-radius: 0%;
            // border-radius: 4px;
            // background: var(--grey-color-ghost-white-ghost-white-100, #FBFCFF);

            /* Shadow/lg */
            box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
        }
        .it-select-input:empty::before{
          content: "区号";
          font-size: 12px;
          color: #2b2e37;
          opacity: 0.6;
        }
        .uparrow{
            height: 12px;
            padding-right: 4px;
            width: 12px;
          }
        // .triangle-down {
        //   width: 0;
        //   height: 0;
        //   border-left: 6px solid transparent;
        //   border-right: 6px solid transparent;
        //   border-top: 4px solid rgba(201, 201, 201, 1);
        //   position: absolute;
        //   top: 50%;
        //   right: 4px;
        //   transform: translateY(-50%) rotate(0deg);
        //   transition: transform 0.3s ease-in-out;
        // }
      }
      .formitem3{
        // margin-top: -3px;
        // height: 74px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        display: flex;
        gap: 8px;
        // height: 140px;
        width: 320px;
        .formlabel{
          // margin-top: 24px;
          color: var(--grey-color-ebony-ebony-100, #2B2E37);

          /* Chinese Mode/Bold Title24 */
          font-family: 'NotoSansSC-Regular';
          font-size: 12px;
          font-weight: 700;
          line-height: 32px; /* 133.333% */
        }
        .divforminput{
          // margin-top: 24px;
          display: flex;
          align-items: center;
          outline: none;
          width: 320px;
          border-radius: 4px;
          border: 0.5px solid var(--grey-color-ebony-ebony-40, rgba(43, 46, 55, 0.40));
          flex-shrink: 0;
          background-color: rgba($color: #000000, $alpha: 0);
          .forminput{
          padding: 4px;
          font-size: 12px;
          width: 284px;
          border: 0;
          height: 24px;
          flex-shrink: 0;
          outline: none;
          background-color: rgba($color: #000000, $alpha: 0);
          }
        }
        .list{
          padding-top:8px ;
          position: absolute;
          width: 100%;
          height: 200px;
          top: calc(100%);
          left: 0;
          background: #ffffff;
          box-shadow: 0px 0px 15px rgba(32, 110, 242, 0.15);
          border-radius: 10px;
          max-height: 264px;
          overflow-y: auto;
          overflow-x: hidden;
          z-index: 10;
        }
         .list ul li{
          color: var(--grey-color-ebony-ebony-80, rgba(43, 46, 55, 0.80));
          font-family: 'Outfit-Regular';
          font-size: 12px;
          font-weight: 500;
          /* margin-top: 28px; */
          display: flex;
          padding: 2px 12px;
          align-items: center;
          gap: 8px;
          align-self: stretch;
          cursor: pointer;
          height: 24px;
          /* line-height: 44px; */
          color: #333333;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          }
          .list ul li:hover{
            background-color: #cccccc;
          }
        .uparrow{
          height: 16px;
          width: 16px;
        }
      }
      .formitem1{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        display: flex;
        gap: 8px;
        // height: 140px;
        width: 320px;
        .formlabel{
          // margin-top: 24px;
          color: var(--grey-color-ebony-ebony-100, #2B2E37);

          /* Chinese Mode/Bold Title24 */
          font-family: 'NotoSansSC-Regular';
          font-size: 12px;
          font-weight: 700;
          line-height: 32px; /* 133.333% */
        }
        .divforminput{
          // margin-top: 24px;
          display: flex;
          align-items: center;
          outline: none;
          width: 320px;
          border-radius: 4px;
          border: 0.5px solid var(--grey-color-ebony-ebony-40, rgba(43, 46, 55, 0.40));
          flex-shrink: 0;
          background-color: rgba($color: #000000, $alpha: 0);
          .forminput{
          padding: 4px;
          font-size: 12px;
          width: 284px;
          border: 0;
          height: 24px;
          flex-shrink: 0;
          outline: none;
          background-color: rgba($color: #000000, $alpha: 0);
          }
        }
        .list{
          padding-top:8px ;
          position: absolute;
          width: 100%;
          height: 120px;
          top: calc(100%);
          left: 0;
          background: #ffffff;
          box-shadow: 0px 0px 15px rgba(32, 110, 242, 0.15);
          border-radius: 10px;
          max-height: 264px;
          overflow-y: auto;
          overflow-x: hidden;
          z-index: 10;
        }
         .list ul li{
          color: var(--grey-color-ebony-ebony-80, rgba(43, 46, 55, 0.80));
          font-family: 'Outfit-Regular';
          font-size: 12px;
          font-weight: 500;
          /* margin-top: 28px; */
          display: flex;
          padding: 2px 12px;
          align-items: center;
          gap: 8px;
          align-self: stretch;
          cursor: pointer;
          height: 24px;
          /* line-height: 44px; */
          color: #333333;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          }
          .list ul li:hover{
            background-color: #cccccc;
          }
        .uparrow{
          height: 16px;
          width: 16px;
        }
      }
      .formitem0{
        // height: 84px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        display: flex;
        gap: 8px;
        // height: 140px;
        width: 320px;
        .formlabel{
          color: var(--grey-color-ebony-ebony-100, #2B2E37);

          /* Chinese Mode/Bold Title24 */
          font-family: 'NotoSansSC-Regular';
          font-size: 12px;
          font-weight: 700;
          line-height: 32px; /* 133.333% */
        }
          .divforminput{
          // margin-top: 24px;
          display: flex;
          align-items: center;
          outline: none;
          width: 320px;
          border-radius: 4px;
          border: 0.5px solid var(--grey-color-ebony-ebony-40, rgba(43, 46, 55, 0.40));
          flex-shrink: 0;
          background-color: rgba($color: #000000, $alpha: 0);
          .forminput{
          padding: 4px;
          font-size: 12px;
          width: 320px;
          border: 0;
          height: 24px;
          flex-shrink: 0;
          outline: none;
          background-color: rgba($color: #000000, $alpha: 0);
          }
        }
     }
    }
  }
}
    .select{
      display: flex;
      align-items: center;
      gap: 10px;
      .rectangle{
        width: 9px;
        height: 9px;
        transform: rotate(45deg);
        background: var(--grey-color-ghost-white-ghost-white-100, #FBFCFF);
        transition: transform 1s;
        cursor: pointer;
      }
      .activerec{
        cursor: pointer;
        width: 15px;
        height: 15px;
        transform: rotate(-135deg);
        background: var(--functional-colors-primary-primary-100, #0029FF);
        transition: transform 1s;
        animation: fade 1s;
      }

    }
}
/*screenwidth:398px*/

@keyframes fade {
  0%{opacity: 0;}
  100%{opacity: 1;}
}
@-webkit-keyframes fade {
  0%{opacity: 0;}
  100%{opacity: 1;}
}
</style>
