<template>
  <router-view/>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
* {
  margin: 0;
  padding: 0;
  // height: 100%;
}
html {
    height: 100%;
    margin: 0;
    padding: 0;
}
body {
  background:url('@/assets/images/bg.png');
}
.m-phone-number-input[data-v-195ed97c]{
  background: white;
  width: 100%;
}

.m-input-wrapper.--default-border[data-v-2c0fda6c]{
  border: none;
}
.m-input.--has-label .m-input-input{
  width: 100%;
}
.m-phone-number-input[data-v-195ed97c]{
  width: 100%;
}
.m-select-list__scroll-wrapper{
  width: 480px;
 background-color: white;
}
.m-input,--is-focused,m-select-list__search-input,--info.--sm{
  background-color: white;
  text-align: center;
}
.m-select-list__no-results{
  background-color: white;
}
.m-input.--is-focused .m-input-wrapper[data-v-2cofda6c]{
  border: none;
}
.m-input-wrapper.maz-border-info.maz-rounded{
  border: none;
}
.m-input-wrapper.maz-border-danger.maz-rounded{
  width: 340px;
  border: solid 2px;
  color: red;
  height: 100%;
}
.m-input-wrapper.maz-border-success.maz-rounded{
  width: 340px;
  border: none;
  height: 100%;
}
.m-phone-number-input:not(.--no-flags) .m-phone-number-input__select[data-v-195ed97c] .m-input-wrapper .m-select-input{
  font-size: 14px;
  padding-left: 2.75rem!important;
}
@media screen and (max-width:640px){
    .m-input-wrapper.maz-border-danger.maz-rounded{
    width: 300px;
    border: solid 2px;
    color: red;
    height: 100%;
  }
  .m-input-wrapper.maz-border-success.maz-rounded{
    width: 300px;
    border: none;
    height: 100%;
  }
  .m-select-list__scroll-wrapper{
  width: 300px;
 background-color: white;
}
}
</style>
